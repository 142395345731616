<template>
  <v-container>
    <v-row>
      <v-col cols="6"></v-col>
      <v-col cols="6">
        <!-- <v-menu transition="scale-transition" origin="right top" bottom left>
          <template v-slot:activator="{ on }">
            <v-btn class="ma-0" icon v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list class="app-card-action">
            <v-list-item @click="$router.push('/product/managemodifiers/back')">
              <v-icon class="mr-3 ">keyboard_backspace</v-icon>
              <span>Back to Modifiers</span>
            </v-list-item>
            <v-list-item v-if="modifier.id" @click="deleteModifier">
              <v-icon class="mr-3 " color="red">delete</v-icon>
              <span>{{ $t('Common.delete') }}</span>
            </v-list-item>
          </v-list>
        </v-menu>-->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>Food Modifier</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="7">
        <v-text-field
          placeholder="For example: Salad Dressing Choice"
          label="Modifier name"
          v-model="modifier.name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" sm="4" md="3">
        <v-select
          :items="amounts"
          v-model="modifier.min"
          label="Minimum Required"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <v-select
          :items="amounts"
          v-model="modifier.max"
          label="Maximum Selection"
        ></v-select>
      </v-col>
      <v-col cols="2" sm="4" md="3">
        <small>
          This is a
          <template v-if="modifier.min == 0">
            <i>Optional</i>
          </template>
          <template v-if="modifier.min > 0">
            <b>Required</b>
          </template>
          Modifer
        </small>
      </v-col>
    </v-row>
    <v-row></v-row>
    <v-row>
      <v-col cols="4">
        <h4>
          Modifier Options:
          <v-btn text small color="primary" @click.native.stop="addOption">
            <v-icon>add</v-icon>
          </v-btn>
        </h4>
      </v-col>
    </v-row>
    <v-row class="my-3" v-if="modifier.options">
      <v-col cols="12">
        <v-btn
          color="success"
          class="mr-3"
          @click.prevent="save"
          :disabled="!canSave"
          >Save</v-btn
        >
        <v-btn @click="$router.go(-1)">Cancel</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mb-2">
        <draggable
          :list="modifier.options"
          class="options-group"
          handle=".handle"
          :move="moved"
          @change="changeOrder"
        >
          <template v-for="(option, index) in modifier.options">
            <v-col cols="12" md="10" :key="index" class="py-1">
              <v-card>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="7" class="d-flex">
                      <v-icon class="handle mr-2">mdi-arrow-all</v-icon>
                      <v-text-field
                        label="Name of option"
                        placeholder="(i.e. Avocado)"
                        v-model="modifier.options[index].name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-currency-field
                        label="Optional Fee"
                        v-bind="currency_config"
                        v-model="modifier.options[index].price"
                      ></v-currency-field>
                      <!-- <v-text-field
                      label="Optional Fee"
                      prefix="$"
                      v-model="modifier.options[index].price"
                    ></v-text-field>-->
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                      <v-checkbox
                        small
                        light
                        color="info"
                        label="Available"
                        v-model="modifier.options[index].available"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="1">
                      <v-icon color="red" @click="deleteOption(index)"
                        >delete</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </draggable>
      </v-col>
    </v-row>

    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      heading="Deleting Modifier"
      message="Some products may use this modifier. Are you sure you want to delete this Modifier?"
      @onConfirm="onDeleteModifier"
    ></delete-confirmation-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import Draggable from 'vuedraggable'
import currency from 'v-currency-field'
Vue.use(currency)

export default {
  components: {
    Draggable
  },
  data() {
    return {
      amounts: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20
      ],
      modifier: { options: [] },
      tempList: [],
      currency_config: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: 0.0,
        max: Number.MAX_SAFE_INTEGER
      }
    }
  },
  computed: {
    canSave() {
      if (!this.modifier.name || !this.modifier.options) return false
      if (this.modifier.min === null || this.modifier.min === undefined)
        return false
      for (let option of this.modifier.options) {
        if (!option.name) {
          return false
        }
      }
      return true
    }
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.$store.dispatch('loadModifier', this.$route.params.id).then(m => {
        this.modifier = m
        if (this.modifier.options && this.modifier.options.length) {
          this.modifier.options = this.modifier.options
            .map((opt, k) => ({
              ...opt,
              no: opt.no || k
            }))
            .sort((a, b) => a.no - b.no)
        } else {
          this.modifier.options = []
        }
      })
    }
  },
  methods: {
    moved(e) {
      this.tempList = e.relatedContext.list
    },
    changeOrder() {
      this.modifier.options = this.tempList.map((item, k) => ({
        ...item,
        no: k
      }))
    },
    addOption() {
      this.modifier.options = this.modifier.options || []
      this.modifier.options.unshift({
        id: `${Math.floor(Math.random()) + new Date().getTime()}`,
        name: '',
        price: 0.0,
        available: true
      })
      console.log(this.modifier.options, 'this.modifier.options')
    },
    save() {
      this.$store.dispatch('saveModifier', this.modifier).then(id => {
        this.$store.dispatch('setMessage', {
          title: 'Saved',
          body: 'Option saved successfully'
        })
        if (id) {
          this.modifier.id = id
        }
      })
    },
    deleteModifier() {
      this.$refs.deleteConfirmationDialog.openDialog()
    },
    onDeleteModifier() {
      this.$store.dispatch('deleteModifier', this.modifier.id).then(() => {
        this.$router.push('/product/modifiers')
        this.$refs.deleteConfirmationDialog.close()
      })
    },
    deleteOption(index) {
      console.log('Deleting option:', index)
      this.modifier.options.splice(index, 1)

      //this.modifier.options = this.modifier.options.splice(index, 1)
    }
  }
}
</script>

<style></style>
